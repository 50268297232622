:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #fff; /* Light green background */
  --secondary-bg-color: #91b0ff; /* Lighter green background */
  --accent-color: #005089; /* Green accent color from logo */
}

.App {
  font-family: var(--text-font);
  text-align: center;
  /* background-color: var(--primary-bg-color); */
  /* background-image: url("/background_img.jpeg"); */
  /* background-image: url("/background_img.jpeg"); */
  color: #333; /* Dark color for text contrast */
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

header {
  display: none;
}

header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff; /* Dark color for contrast */
  margin: auto;
  text-align: center;
  font-weight: 500;
}

.menu button {
  background: none;
  border: none;
}

nav {
  display: none;
  background-color: #fff; /* Light green background */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}

.navItems {
  padding: 8px;
  background-color: #fff; /* Lighter green background - changed for consistency*/ 
  width: 100%;
  max-width: 340px;
}

.navPromptWrapper {
  /* border-bottom: 1px solid #000;  */
  padding: 10px;
  height: calc(100vh - 370px);
  overflow-y: auto;
}

.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}

.navPrompt a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}

.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #010101; /* Dark color for text contrast */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}

#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
}

pre {
  font-family: var(--text-font);
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navCloseIcon {
  width: 20%;
  margin: 10px;
}

nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  display: inline;
  /* border-right: 1px solid #333; */
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.1);
  background-color: #005089; /* Light green background */
}

.sideMenuButton {
  
  color: #005089;
  cursor: pointer;
  background-color: #d7d7d7; /* Lighter green background on hover */
  border: 1px solid #fff; /* Light border color */
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  font-weight: 500;
}

.navPrompt:hover,
.card:hover {
  color: #005089;
  cursor: pointer;
  background-color: #d7d7d7; /* Lighter green background on hover */
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}


.sideMenuButton:hover{
  color: #fff;
  cursor: pointer;
  background-color: #005089; /* Lighter green background on hover */
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
  color: #005089;
}

.chatBox {
  flex: 1;
  background-color: #fff; /* Light green background */
  position: relative;
  /* line-height: 24px; */
  width: 100%;
  height: 100vh;
  /* vertical-align: auto; */
  color: #333; /* Dark color for text contrast */
  font-size: 16px;
  padding-top: 25px;
  padding-bottom: 20px;
  /* background-image: url('../public/background_img.jpeg'); */
  background-image:
      linear-gradient(90deg,
          rgb(255, 255, 255, 0),
          rgba(8, 83, 156, 0.75)), 
            url('../public/background_img.jpeg');
}

#introsection::before,
#introsection::after {
  float: left;
  color: var(--accent-color); /* Green accent color */
}

#introsection {
  text-align: left;
  padding: 20px;
  /* background-image: url('../public/background_img.jpeg'); */
  height: 100vh;
}

.intro h1 {
  padding-top: 20px;
  color: #fff; /* Dark color for contrast */
}

#introsection h2 {
  font-size: 16px;
  font-weight: 400;
  color: #fff; /* Dark color for text contrast */
}

#introsection ul {
  list-style-type: square;
  color: #fff; /* Dark color for text contrast */
}

#introsection p {
  color: #fff; /* Dark color for text contrast */
}

.chatLogWrapper {
  height: 80vh;
  overflow-y: auto;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  background-color: #91b0ff; /* Lighter green background */
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: #005089; /* Lighter green scrollbar thumb color */
  border-radius: 20px;
}

::-moz-scrollbar {
  width: 8px;
  background-color: #d4e9c6; /* Lighter green background */
}

::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: #b3cc99; /* Lighter green scrollbar thumb color */
}

.chatPromptMainContainer {
  background-color: #005089;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
}

.chatPromptWrapper {
  color: #fff;
  font-weight: 600;
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}

.botMessageMainContainer {
  width: 100%;
  background-color: #fff; 
  position: relative;
  border-radius: 10px;
}

.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  column-gap: 25px;
}

.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid black; /* Light border color */
  background-color: white; /* Lighter green background */
  color: #333; /* Dark color for text contrast */
  cursor: pointer;
}

.stop-messgage:hover {
  background-color: #cce0b3; /* Lighter green background on hover */
}

.errorMessage {
  color: #ef4444; /* Error message color */
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}

#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  margin-top: 40px;
  left: 50%;
  right: 50%;
  /* bottom: 0; */
  position: relative;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 200px;
  height: 55px;
  background-color: #ffffff; /* Light green background */
  border-radius: 5px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1); /* Light shadow */
  display: flex;
  border: 1px solid #333;
}

.inputPromptTextarea {
  padding: 10px;
  resize: none;
  background-color: #fff; /* Light green background */
  color: #333; /* Dark color for text contrast */
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
  width: 100%;
  font-size: 18px;
  padding-right: 50px;
}

form {
  width: 100%;
  padding-top: 6px;  
}

form button {
  background: #005089; /* Green button color */
  border: none;
  position: absolute;
  top: 10%;
  right: 5px;
  width: 40px;
  height: 45px;
  color: #fff; /* Light text color */
  border-radius: 15%;

}

@media (hover: hover) {
  .navPrompt:hover{
    cursor: pointer;
    background: #b9ccfc; /* Lighter green background on hover */
    border-radius: 5px;
  }
  .sideMenuButton:hover {
    cursor: pointer;
    background: #005089; /* Lighter green background on hover */
    /* border-radius: 20%; */
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
  color: #333; /* Dark color for contrast */
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
  color: #555; /* Dark color for text contrast */
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff; /* Light text color */
  background: #005089; /* Green button color */
  border: none;
  font-size: 16px;
  cursor: pointer;
}

#loginButton:hover {
  background-color: #2b6f00; /* Darker green button color on hover */
}

.prompts svg {
  width: 1.25em;
  margin: 5px;
}

#intro-logo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 11em;
}

@media screen and (min-width: 768px) {
  /* .inputPromptWrapper {
    position: absolute;
  } */
}

@media screen and (max-width: 1024px) {
  .App {
    display: inline-block;
  }
  header {
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    font-weight: 500;
    background: #005089; /* Light green background */
    border-bottom: 1px solid #ccc; /* Light border color */
    /* padding: 4px 0 0 12px; */
  }
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: relative;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}
